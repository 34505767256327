import React from "react";
import Modal from "../../../shared/modal/Modal";
import {withTranslation} from "../../../i18n";
import css from './ConfirmServiceChangeModal.module.scss';
import Button from "../../../shared/button/simpleButton/Button";
import LinksProvider from "../../../services/http/LinksProvider";

const namespacesRequired = ['common'];

const ConfirmServiceChangeModal = ({t, visible, onHide, titleText, bodyText, submitText, cancelText, reRouteUrl, reRouteUrlTarget = "_self"}) => {

  const title = titleText ? titleText : t('common:common.service-change-confirm-title');
  const body = bodyText ? bodyText : t('common:common.service-change-confirm-body');
  const submit = submitText ? submitText : t('common:common.service-change-confirm-confirm');
  const cancel = cancelText ? cancelText : t('common:common.service-change-confirm-cancel');
  const url = reRouteUrl ? reRouteUrl : LinksProvider.getFullM1lRoute(LinksProvider.ROUTES.M1L.HOME);

  return (<Modal visible={visible}
                 title={title}
                 onHide={onHide}
                 customClass={css.confirm_service_change}
  >
  <span className={css.modal_wrapper}>
      <div className={css.modal__body} dangerouslySetInnerHTML={{ __html: body }} />

      <div className={css.modal__buttons}>
        <Button type="button" className={css.btn__light}
                onClick={onHide}>{cancel}</Button>

        <a href={url} target={reRouteUrlTarget}
           className={css.btn__primary}>{submit}</a>
      </div>
    </span>
  </Modal>);
};

export default withTranslation(namespacesRequired)(ConfirmServiceChangeModal);
