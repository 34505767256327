import React from 'react';
import css from './HomeGifts.module.scss';
import GiftsGrid from "../gifts-grid/GiftsGrid";
import Link from "next/link";
import {withTranslation} from '../../../i18n';
import LinksProvider from "../../../services/http/LinksProvider";
import PageLink from "../../common/page-link/PageLink";


const namespacesRequired = ['home'];

const HomeGifts = ({t, gifts}) => {
  return (
    <div className={css.home__gifts}>

      <GiftsGrid customClass={css.home__grid} gifts={gifts}/>

      <div className={css.home__gifts__content}>
        <h2>{t('home:idea-gift')}</h2>
        <PageLink href={LinksProvider.get(LinksProvider.ROUTES.PRODUCT.SEARCH_NO_PARAMS)}>
          <a className={css.btn__primary}>
            <span>{t('home:idea-gift-cta')}</span>
          </a>
        </PageLink>
      </div>
    </div>
  );
};

export default withTranslation(namespacesRequired)(HomeGifts);
