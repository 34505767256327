import React from 'react';
import css from './PromoCodeBanner.module.scss';
import {withTranslation} from "../../../i18n";
import Tooltip from "../../../components/widgets/Tooltip/Tooltip";
import CurrencyService from "../../../services/utils/CurrencyService";

const namespacesRequired = ['common', 'pot'];

const PromoCodeBanner = ({t, code, customClass}) => {

  const PromoCodeBannerTooltipContent = () => {
    return (
      <div>
        {!!code.duration_before_reaching_threshold && (
          <div
            className={css.threshold__disclaimer}>{t('pot:promocode.conditions-diclaimer', {count: code.duration_before_reaching_threshold})}</div>
        )}
        <ul>
          {!!code.min_participants_number && (
            <li>
              - {t('pot:promocode.min-participants', {count: code.min_participants_number})}
            </li>
          )}

          {!!code.min_collected_amount && (
            <li>
              - {t('pot:promocode.min-amount', {amount: (code.min_collected_amount / 100)})}
            </li>
          )}
        </ul>
      </div>

    );
  };

  return (
    <div className={`${css.promocode__banner} ${customClass ? customClass : ''}`}>
      <span dangerouslySetInnerHTML={{
        __html: code.banner_text ? code.banner_text : t('pot:promocode.offer', {
          amount: CurrencyService.centsToEuro(code.amount),
          reference: code.reference
        })
      }}/>
      {(!!code.min_participants_number || !!code.min_collected_amount) && (
        <Tooltip trigger="click" customClass={css.tooltip}>
          <PromoCodeBannerTooltipContent/>
        </Tooltip>
      )}
    </div>);
};

export default withTranslation(namespacesRequired)(PromoCodeBanner);
