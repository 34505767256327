import React, {useEffect, useState} from 'react';
import css from './GiftsGrid.module.scss';

const ODD_COLUMNS_SPEED = 0.2;
const EVEN_COLUMNS_SPEED = 0.1;

const GiftsGrid = ({customClass, gifts}) => {

  const splitArray = function (array, size) {
    if (!array.length) {
      return [];
    }
    const head = array.slice(0, size);
    const tail = array.slice(size);

    return [head, ...splitArray(tail, size)];
  };

  const rows = splitArray(gifts, 3);

  const [gridRef] = useState(React.createRef());

  const offset = (el) => {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {top: rect.top + scrollTop, left: rect.left + scrollLeft}
  };

  const handleScroll = () => {
    if (!gridRef || !gridRef.current || window.scrollY == undefined) {
      return;
    }

    const scroll = window.scrollY - offset(gridRef.current).top + gridRef.current.offsetHeight;
    if (scroll < 0) {
      return;
    }

    const domRows = gridRef.current.children[0].children;
    for (let i = 1; i <= domRows.length; i++) {
      const odd = i % 2 == 1;
      const transform = `translateY(${-scroll * (odd ? ODD_COLUMNS_SPEED : EVEN_COLUMNS_SPEED)}px)`;
      domRows[i - 1].style.transform = transform;
      domRows[i - 1].style.MozTransform = transform;
      domRows[i - 1].style.webkitTransform = transform;
    }

  };

  useEffect(() => {
    if (window.innerWidth >= 875) {
      window.addEventListener('scroll', handleScroll);
      handleScroll();
    }
  }, []);

  return (<div className={`${css.gifts} ${customClass || ''}`} ref={gridRef}>
      <div className={css.gifts__grid}>
        {rows.map((row, key) => {
          return (<div key={key} className={css.gifts__row}>
            {row.map((gift, index) => {
              return (<div key={index} className={css.gift}>
                <div className={css.gift__img}
                     style={{backgroundImage: `url(${gift?.cover_photo?.storage_service_url})`}}>
                </div>
                <div>
                  <h2>{gift.brand}</h2>
                  <p>{gift.name}</p>
                </div>
              </div>);
            })}
          </div>);
        })}
      </div>
    </div>

  );
};

export default GiftsGrid;
