import React, { useEffect, useState } from 'react';
import css from './HomeHeader.module.scss';
import PageWrapper from "../../../shared/layout/page/PageWrapper";
import Heading from "../../../shared/textes/Heading/Heading";
import Select from "../../../shared/form/select/Select";
import Link from 'next/link';
import { withTranslation } from '../../../i18n';
import { PotCategory } from "../../../models/Pot";
import CustomForm from "../../../shared/form/custom-form/CustomForm";
import LinksProvider from "../../../services/http/LinksProvider";
import ConfirmServiceChangeModal
  from "../../modals/confirm-service-change-modal/ConfirmServiceChangeModal";
import PromoCodesService from "../../../services/domain/PromoCodesService";
import PromoCodeBanner from "../../../shared/form/promocode-banner/PromoCodeBanner";
import PageLink from "../../common/page-link/PageLink";

const namespacesRequired = ['home', 'common'];

const HomeHeader = ({ t, pagesData }) => {

  const [selectedCategory, setSelectedCategory] = useState(0);
  const [confirmServiceChangeModalShown, setConfirmServiceChangeModalShown] = useState(false);
  const [isCategoryOtherSelected, setIsCategoryOtherSelected] = useState(false);
  const [bannerPromocode, setBannerPromocode] = useState(null);

  useEffect(() => {
    if (pagesData && pagesData.promoCodeBanner) {
      PromoCodesService.get(pagesData.promoCodeBanner.reference)
        .then(setBannerPromocode);
    }
  }, []);

  const selectCategory = (newValue, oldValue) => {
    if (newValue.value !== PotCategory.WEDDING_LIST && newValue.value !== PotCategory.OTHER) {
      setSelectedCategory(newValue.value);
      return true;
    } else {
      if (newValue.value === PotCategory.WEDDING_LIST) {
        setConfirmServiceChangeModalShown(true);
      } else if (newValue.value === PotCategory.OTHER) {
        setIsCategoryOtherSelected(true);
      }
      return false;
    }
  };

  return (<div className={`${css.home__header} ${css[`cat-${selectedCategory}`]} fadein`}>

    <PageWrapper customClass={css.home__wrapper}>
      <Heading headerContent={t('home:create-pot-for')} customClass={css.home__title} />

      <ConfirmServiceChangeModal visible={confirmServiceChangeModalShown}
        onHide={() => {
          setConfirmServiceChangeModalShown(false);
          setSelectedCategory(PotCategory.OTHER);
        }} />
      <ConfirmServiceChangeModal
        visible={isCategoryOtherSelected}
        reRouteUrl={`${LinksProvider.getFullRoute(LinksProvider.ROUTES.FAQ)}?categoryIndex=0&themeIndex=0&questionIndex=0`}
        titleText={t('pot-creation:popup-category-other.title')}
        bodyText={t('pot-creation:popup-category-other.body')}
        cancelText={t('pot-creation:popup-category-other.cancel')}
        submitText={t('pot-creation:popup-category-other.submit')}
        reRouteUrlTarget={"_target"}
        onHide={() => {
          setSelectedCategory(PotCategory.OTHER);
          setIsCategoryOtherSelected(false);
        }}
      />

      <CustomForm render={() => (<Select customClass={css.home__categories}
        customSelect={css.home__select}
        customControl={css.home__control}
        customMenu={css.home__menu}
        customPlaceholder={css.home__placeholder}
        customArrow={css.home__arrow}
        inputName="category"
        inputId="category"
        value={selectedCategory}
        onChange={selectCategory}
        options={Object.values(PotCategory).map((val) => {
          return {
            value: val,
            label: t(`pot-categories.pot-category-${val}`)
          };
        })} />)}>
      </CustomForm>

      <PageLink
        href={`${LinksProvider.get(LinksProvider.ROUTES.POT.CREATE)}?category=${selectedCategory
          || 0}`} as={`${LinksProvider.get(LinksProvider.ROUTES.POT.CREATE)}?category=${selectedCategory
            || 0}`}>
        <a className={css.btn__primary}><span>{t('home:create-pot')}</span></a>
      </PageLink>

      {bannerPromocode && (<PromoCodeBanner code={bannerPromocode} customClass={css.home__promocode} />)}

    </PageWrapper>
  </div>);
};

export default withTranslation(namespacesRequired)(HomeHeader);
