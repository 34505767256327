export const GIFT_CARD_MARCHANT_TYPE = {
  GL: 0,
  BHV: 1
};

export const HOME_CATEGORIES = [
  {
    img: "/static/images/home/categories/category-1.jpg",
    label: "home:categories.category-1",
    link: "https://www.galerieslafayette.com/h/femme",
  },
  {
    img: "/static/images/home/categories/category-2.jpg",
    label: "home:categories.category-2",
    link: "https://www.galerieslafayette.com/c/femme/accessoires",
  },
  {
    img: "/static/images/home/categories/category-3.jpg",
    label: "home:categories.category-3",
    link: "https://www.galerieslafayette.com/h/enfant",
  },
  {
    img: "/static/images/home/categories/category-4.jpg",
    label: "home:categories.category-4",
    link: "https://www.galerieslafayette.com/h/beaute",
  },
  {
    img: "/static/images/home/categories/category-5.jpg",
    label: "home:categories.category-5",
    link: "https://www.galerieslafayette.com/c/maison/multimedia",
  },
  {
    img: "/static/images/home/categories/category-6.jpg",
    label: "home:categories.category-6",
    link: "https://www.galerieslafayette.com/h/maison",
  },
];
