import React, {useState, useEffect} from 'react';
import css from './HomeAdvantages.module.scss';
import Divider from '../../../shared/ui/divider/Divider';
import {withTranslation} from '../../../i18n';

const namespacesRequired = ['home', 'common'];
import AdvantagesCarousel from "../advantages-carousel/AdvantagesCarousel";

const HomeAdvantages = ({t}) => {

  const [windowWidth, setWindowWidth] = useState(null);

  let handlePageResize = (e) => {
    setWindowWidth(window.innerWidth); // TODO ROMAIN VERIFY WITH SAMEH
  };

  useEffect(() => {
    window.addEventListener('resize', handlePageResize);
    setWindowWidth(window.innerWidth);
  });

  return (
      <React.Fragment>
        <Divider/>
        <h2 className={css.home__section__title}>{t('home:advantages-title')}</h2>
        <div className={css.home__advantages}>

          <AdvantagesCarousel/>

          <React.Fragment>
            <div className={css.item}>
              <img src="/static/images/pictos/icon-gift.svg"/>
              <h3>{t('home:advantage-cashback-title')}</h3>
              <p>{t('home:advantage-cashback-desc')}</p>
            </div>
            <div className={css.item}>
              <img src="/static/images/pictos/icon-shopping.svg"/>
              <h3>{t('home:advantage-store-title')}</h3>
              <p>{t('home:advantage-store-desc')}</p>
            </div>
            <div className={css.item}>
              <img src="/static/images/pictos/icon-shield.svg"/>
              <h3>{t('home:advantage-secure-title')}</h3>
              <p className={css.secure__desc}>{t('home:advantage-secure-desc')}</p>
            </div>
          </React.Fragment>

        </div>
      </React.Fragment>
  );
};

export default withTranslation(namespacesRequired)(HomeAdvantages);
