import React, {useState} from 'react';
import css from './HomeSpend.module.scss';
import Divider from '../../../shared/ui/divider/Divider';
import Link from 'next/link';
import {withTranslation} from '../../../i18n';
import Tooltip from "../../widgets/Tooltip/Tooltip";
import LinksProvider from '../../../services/http/LinksProvider';
import PageLink from "../../common/page-link/PageLink";

const namespacesRequired = ['home', 'common'];

const HomeSpend = ({t, pagesData}) => {

  return (
      <React.Fragment>
        <Divider/>

        <div className={css.home__section__title_container}>
          <h2 className={css.home__section__title}>{t('home:spend')}</h2>
          <h2 className={css.home__section__subtitle}>{t('home:the-pot')}</h2>
        </div>

        <div className={css.home__spend__wrapper}>
          <div className={css.home__spend}>

            <div className={`${css.home__spend__bloc} ${css.card}`}>

              <PageLink href={LinksProvider.get(LinksProvider.ROUTES.SPENDING_INFORMATION)}>
                <a className={css.home__spend__cta}>
                  <div>
                  <h2>
                    {t('home:generate-card')}
                  </h2>
                  <div className={css.card__info}>
                      <p>{t('home:bonus-tooltip.title', {bonus_percentage: pagesData.bonus.bonus_percentage})}</p>
                      <div>
                        <Tooltip
                            trigger={'hover'}
                            triggerComponent={
                              <img
                                  src="/static/images/pictos/icon-tooltip.svg"
                              />}
                        >
                          <div className={css.tooltip_mobile}>
                            {t('home:bonus-tooltip.content', {bonus_percentage: pagesData.bonus.bonus_percentage})}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <img src="/static/images/pictos/icon-arrow-right.svg"/>
                </a>
              </PageLink>

            </div>

            <div className={`${css.flex__column}`}>
              <div className={`${css.home__spend__bloc} ${css.gift}`}>
                <PageLink href={LinksProvider.get(LinksProvider.ROUTES.SPENDING_INFORMATION)}>
                  <a className={css.home__spend__cta}>
                    <h2>{t('home:offer-pot')}</h2>
                    <img src="/static/images/pictos/icon-arrow-right.svg"/>
                  </a>
                </PageLink>
              </div>
              <div className={`${css.home__spend__bloc} ${css.cashout}`}>
                <PageLink href={LinksProvider.get(LinksProvider.ROUTES.SPENDING_INFORMATION)}>
                  <a className={css.home__spend__cta}>
                    <h2>{t('home:cashback-withdrawal')}</h2>
                    <img src="/static/images/pictos/icon-arrow-right.svg"/>
                  </a>
                </PageLink>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>);
};

export default withTranslation(namespacesRequired)(HomeSpend);
