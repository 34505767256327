import React from "react";
import css from './Tooltip.module.scss';
import Popup from "reactjs-popup";

const Tooltip = (props) => {

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (<div className={`${css.tooltip} ${props.customClass ? props.customClass : ''}`} onClick={stopPropagation}>
    <Popup
        trigger={props.triggerComponent || (<button type="button"
                                                    className="tooltip__trigger"
        >
          <img srcSet={'/static/images/pictos/icon-question-mark-black.svg'}/>
        </button>)}
        position={props.position ? props.position : 'right center'}
        closeOnDocumentClick
        repositionOnResize
        keepTooltipInside
        on={props.trigger ? props.trigger : 'hover'}
    >
      <React.Fragment>
        {props.children && (
            <div className="tooltip__body">{props.children}</div>
        )}
        {props.contentWithHtml && (
            <div className="tooltip__body" dangerouslySetInnerHTML={{__html: props.contentWithHtml}}/>
        )}
      </React.Fragment>

    </Popup>
  </div>);
};

export default Tooltip;

