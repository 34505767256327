export const ProductGender = {
  ALL: 3,
  FEMALE: 1,
  MALE: 0,
};

export const ProductCategory = {
  MODE: 1,
  VOYAGE: 4,
  MAISON: 2,
  HIGHTECH: 3,
  BEAUTE: 5,
  LOISIRS: 6
};

export const ProductAgeRange = {
  ALL: 0,
  ZER0_FIVE: 1,
  FIVE_FIFTEEN: 2,
  FIFTEEN_TWENTYFIVE: 3,
  TWENTYFIVE_THIRTYFIVE: 4,
  THIRTYFIVE_FOURTYFIVE: 5,
  FOURTYFIVE_SIXTY: 6,
  SIXTY_PLUS: 7
};

export const ProductType = {
  GIFT: 0,
  MARRIAGE: 1,
  GIFT_MARRIAGE: 2
};

export const ProductSortCriteria = {
  PLACEMENT: 0,
  ASC_PRICE: 1,
  DESC_PRICE: 2,
  NEW: 3
};

export const ProductDefaultMaxPrice = 1000;
export const ProductDefaultMinPrice = 0;

export const GLStoreReference = 'GL';
export const BHVStoreReference = 'BHV';

