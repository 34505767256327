import React from "react";
import Head from "next/head";
import LinksProvider from "../../services/http/LinksProvider";
import {withTranslation} from "../../i18n";
import { useRouter } from 'next/router';

const namespacesRequired = ['home'];

const OpenGraphHead = ({title, meta_title, description, url, image, t, ...props}) => {
  const router = useRouter();
  const og_description = description ? description : t('home:meta-description');
  const og_image = image ? image : LinksProvider.getFullRoute('/static/images/categories/default-img-0.jpg');
  const og_url = url ? url : router.pathname;
  const og_title = title ? title : t('home:meta-title');

  return (
    <Head>
      <title>{meta_title ? meta_title : og_title}</title>
      <meta name="description" content={og_description}/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={og_title}/>
      <meta property="og:url" content={og_url}/>
      <meta property="og:description" content={og_description}/>
      <meta property="fb:app_id" content={process.env.REACT_FACEBOOK_APP_ID}/>
      <meta property="og:image" content={og_image}/>
      <meta property="og:image:width" content="1200"/>
      <meta property="og:image:height" content="630"/>
      {props.children}
    </Head>
  );
};

export default withTranslation(namespacesRequired)(OpenGraphHead);
