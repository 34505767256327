import React from "react";
import Divider from "../../../shared/ui/divider/Divider";
import css from "./HomeBrands.module.scss";
import { withTranslation } from "../../../i18n";
import { HOME_CATEGORIES } from "../../../models/GiftCard";

const namespacesRequired = ["home"];

const HomeBrands = ({ t }) => {
  return (
    <div>
      <Divider />
      <h2
        className={css.home__section__title}
        dangerouslySetInnerHTML={{ __html: t("home:who-are-we") }}
      />
      <p className={css.home__section__desc} dangerouslySetInnerHTML={{ __html: t("home:who-are-we-desc")}} />

      <div className={css.home__brands}>
        {HOME_CATEGORIES.map((category, i) => {
          return (
            <a
              href={category.link}
              className={css.home__brand}
              key={i}
              target="_blank"
            >
              <div style={{ backgroundImage: `url(${category.img})` }} />
              <label className={css.home__brand__title}>
                {t(category.label)}
              </label>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation(namespacesRequired)(HomeBrands);
