import React from "react";
import Swiper from "react-id-swiper";
import {withTranslation} from "../../../i18n";

const namespacesRequired = ['home', 'common'];
import css from '../home-advantages/HomeAdvantages.module.scss';

const AdvantagesCarousel = ({t}) => {
  const params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    }
  };

  return (
    <div className={css.advantages__carousel}>
      <Swiper {...params}>
        <div className={css.home__advantages__item}>
          <img src="/static/images/pictos/icon-gift.svg"/>
          <h3>{t('home:advantage-cashback-title')}</h3>
          <p>{t('home:advantage-cashback-desc')}</p>
        </div>
        <div className={css.home__advantages__item}>
          <img src="/static/images/pictos/icon-shopping.svg"/>
          <h3>{t('home:advantage-store-title')}</h3>
          <p>{t('home:advantage-store-desc')}</p>
        </div>
        <div className={css.home__advantages__item}>
          <img src="/static/images/pictos/icon-shield.svg"/>
          <h3>{t('home:advantage-secure-title')}</h3>
          <p>{t('home:advantage-secure-desc')}</p>
        </div>
      </Swiper>
    </div>
  );
};

export default withTranslation(namespacesRequired)(AdvantagesCarousel)
