import React from 'react';
import PropTypes from 'prop-types';
import css from './Heading.module.scss';


const Heading = (props) => {
  return (
    <section>
      <h1 className={`${css.heading} ${props.customClass}`}
          dangerouslySetInnerHTML={{__html: props.headerContent}}/>
    </section>
  );
};

Heading.propTypes = {
  headerContent: PropTypes.string,
  class: PropTypes.string,
};

Heading.defaultProps = {
  class: '',
  headerContent: "Page title exemple",
};

export default Heading;
