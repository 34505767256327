import React from 'react';
import {Formik} from "formik";
import ErrorHandler from "../../../services/error/ErrorHandler";
import {withTranslation} from '../../../i18n';
import Notification from "../../notification/Notification";
import css from "./CustomForm.module.scss";

const namespacesRequired = ['common'];

class CustomForm extends React.Component {
  onSubmit(props) {
    return (values, actions) => {
      ErrorHandler.setErrorActions(actions);
      props.onSubmit(values, actions);
    };
  };

  renderForm(props) {
    return ((renderProps) => {
      ErrorHandler.normalizeValidationErrors(renderProps.errors);
      renderProps.errors = {...renderProps.errors, ...renderProps.status};
      return (<div>
        {(!props.hideGenericErrors && renderProps.status && renderProps.status.genericErrors && renderProps.status.genericErrors.length)
        && renderProps.status.genericErrors.map((value, key) => {
          return <Notification key={key}>
            <div className={css.notification}>
              <div className={css.notification__content}>
                <img src="/static/images/pictos/icon-information.svg"/>
                {props.t(`errors.${value}`)}
              </div>
            </div>
          </Notification>;
        })}
        {props.render(renderProps)}
      </div>);
    });
  };

  render() {
    return (<Formik
      validationSchema={this.props.schema}
      validate={this.props.customValidate}
      onSubmit={this.onSubmit(this.props)}
      initialValues={this.props.initialValues}
      render={this.renderForm(this.props)}/>);
  }
}

export default withTranslation(namespacesRequired)(CustomForm);
