import React, {useState} from "react";
import Dropdown from 'react-dropdown';
import css from './Select.module.scss';
import {Field} from "formik";

const Select = (props) => {

  if ((props.value == null || props.value == undefined) && props.onChange) {
    props.onChange(props.options[0].value, props.inputName);
  }

  const findValue = (val) => {
    const res = props.options.filter(opt => opt.value == val);

    if (!res || !res.length) {
      return null;
    }

    return res[0];
  };

  const [oldValue, setOldValue] = useState(findValue(props.value) || props.options[0]);

  return (<Field name={props.inputName} id={props.inputId}>
    {({field: {value}, form: {setFieldValue}}) => {

      return (
      <div className={`${css.input__wrapper} ${props.customClass}`} ref={props.anchorRef}>
        <label className={css.label}>
          {props.label}
        </label>
        <Dropdown options={props.options}
                  onChange={(newValue) => {
                    if (!props.onChange || props.onChange(newValue, oldValue)) {
                      setFieldValue(props.inputName, newValue.value);
                      setOldValue(newValue);
                    }
                  }}
                  onBlur={props.onBlur}
                  value={findValue(props.value) || props.options[0]}
                  placeholder={props.inputPlaceholder}
                  className={`${css.select} ${props.customSelect}`}
                  controlClassName={`${css.select__control} ${props.customControl}`}
                  placeholderClassName={`${css.select__placeholder} ${props.customPlaceholder}`}
                  menuClassName={`${css.select__menu} ${props.customMenu}`}
                  arrowClassName={` ${css.select__arrow} ${props.customArrow}`}/>
        {(props.errors && props.errors[props.inputName]) && <div
          className={css.input__errors}>
          <p>{props.errors[props.inputName]}</p>
        </div>}
      </div>)}}
  </Field>);
};

Select.defaultProps = {
  inputPlaceholder: "Select an option"
};

export default Select;
