import React from 'react';
import LayoutLanding from '../../shared/layout/LayoutLanding';
import css from './home.module.scss';
import PagesDataService from '../../services/domain/PagesDataService';
import ProductsService from '../../services/domain/ProductsService';
import {withPageWrapper} from '../../services/utils/withPageWrapper';
import HomeHeader from "../../components/home/home-header/HomeHeader";
import HomeAdvantages from '../../components/home/home-advantages/HomeAdvantages';
import HomeSpend from '../../components/home/home-spend/HomeSpend';
import HomeBrands from "../../components/home/home-brands/HomeBrands";
import HomeGifts from "../../components/home/home-gifts/HomeGifts";
import OpenGraphHead from "../../shared/OpenGraphHead/OpenGraphHead";

const namespacesRequired = ['home', 'common', 'concept'];

const Home = ({t, principal, allCookies, pagesData, gifts, products}) => {
  return (<LayoutLanding principal={principal} allCookies={allCookies} pageClass={css.home}>

    <OpenGraphHead title={t('home:meta-title')}
                   description={t('home:meta-description')} />

    <HomeHeader pagesData={pagesData}/>
    <HomeAdvantages/>
    <HomeGifts gifts={gifts.products}/>
    <HomeSpend pagesData={pagesData}/>
    <HomeBrands />
  </LayoutLanding>);
};

Home.getInitialProps = async (ctx) => {
  const pagesData = await PagesDataService.get(ctx);
  let gifts = await ProductsService.find({}, 18, 0, ctx);
  let products = gifts.products;
  if (gifts && products && products.length && products.length < 18) {
    for (let i = 0; i < Math.ceil(18 / gifts.products.length) - 1; i++) {
      products = products.concat(gifts.products);
    }
    gifts.products = products.slice(0, 18);
  }

  return {pagesData, gifts, products};
};

export default withPageWrapper({namespacesRequired: namespacesRequired})(Home);
